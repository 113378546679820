import productAttributesJson from 'config/files/productAttributes.json'

export const DEFAULT_PRODUCT_IMAGE = '/images/kanthal-assortment.jpg'

/**
 * Use to show selected attributes from the product technical specifications
 */

export const includedAttributes = ['erpTechspec', 'erpGradeName'] as IncludedAttributes

/**
 * Used to define attributes that which contain values that we should translate to human readable form
 * You can add translations for these values in src\locales\en\product.json
 * @example
 * erpTechspecThermoCoupleClass = 'C1/SPECIAL' => 'C1 class or special limits'
 * erpTechspecThermoCoupleClass = 'C2/STAND' => 'C2 class or standard limits'
 *
 */

export const extendedAttributes = [
  'erpTechspecMechanicalProperties',
  'erpTechspecWireType',
  'erpTechspecThermoCoupleClass',
  'erpTechspecThermoCoupleLegType',
  'erpTechspecSurfaceCondition',
]

/**
 * Used to define attributes that should be shown in filter facets.
 * We use this array to add facets to the product search query in getProducts function.
 */

export const selectedFilterFacets = [
  'erpGradeName',
  'erpTechspecThermoCoupleClass',
  'erpTechspecMechanicalProperties',
  'erpTechspecSurfaceCondition',
  'erpTechspecWireType',
]

export type IncludedAttributes = ReadonlyArray<string>

/**
 * Used to define how many products should be shown per page
 */
export const LIMIT_PRODUCTS_PER_PAGE = 20

export type DiameterAttributesFilterType = ReadonlyArray<string>

/**
 * Used to define range filters
 */

const diameterAttributesFilter = [
  'erpTechspecDiameterInMm',
  'erpTechspecDiameterInInches',
  'erpTechspecDiameterInSWG',
] as DiameterAttributesFilterType

type ThicknessAttributesFilterType = ReadonlyArray<string>

export const allAttributesFilter = [...diameterAttributesFilter]

export type RangeSliderFiltersType = {
  diameterAttributesFilter: DiameterAttributesFilterType
  thicknessAttributesFilter: ThicknessAttributesFilterType
}

export const rangeSliderFilters = {
  diameterAttributesFilter: diameterAttributesFilter,
  // Add any new filters here
  // newFilter: newFilterArray,
} as RangeSliderFiltersType

/**
 * @returns A Map object with attributes divided by product group
 */
export const attributeGroupsMap = new Map<string, string[]>()
productAttributesJson.forEach((group) => {
  group.keys.forEach((key) => {
    attributeGroupsMap.set(key, group.attributes)
  })
})
